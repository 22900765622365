import React, {
    forwardRef,
    Suspense,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { Canvas } from '@react-three/fiber';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import {
    GridOff,
    GridOn,
    LayersOutlined,
    LayersClearOutlined,
    Explore,
    ExploreOff,
} from '@mui/icons-material';
import TopDownScene from './TopDownScene';
import CameraController from './CameraController';
import { PlateType } from './types';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectPlates,
    setUnsavedChanges,
    updatePlates,
} from './moodboardState';
import { AppDispatch } from 'store';

interface MoodboardProps {
    isDisabled: boolean;
}

const Moodboard = forwardRef<{ takeScreenshot: () => void }, MoodboardProps>(
    (props, ref) => {
        const [gridActive, setGridActive] = useState<boolean>(false);
        const [layersCamera, setLayersCamera] = useState<boolean>(false);
        const [axesHelperActive, setAxesHelperActive] =
            useState<boolean>(false);

        const plates: PlateType[] = useSelector(selectPlates);
        const dispatch: AppDispatch = useDispatch();
        const topDownSceneRef = useRef<{
            takeScreenshot: () => void;
            uploadScreenshot: () => Promise<Variation | null>;
            resetView: () => void;
        }>(null);
        const showPortal = true;
        const platePanelContainer = useRef(null);

        // 'ref' is reference to the parent component (RoomView.tsx)
        // in the 'useImperativeHandle' new function 'takeScreenshot'
        // is declared and forwarded to the parent
        useImperativeHandle(ref, () => ({
            takeScreenshot() {
                if (topDownSceneRef.current) {
                    setGridActive(false);
                    // get the takeScreenshot function from the TopDownScene component
                    topDownSceneRef.current.takeScreenshot();
                }
            },
            uploadScreenshot() {
                if (topDownSceneRef.current) {
                    setGridActive(false);
                    return topDownSceneRef.current.uploadScreenshot();
                }
            },
            resetView() {
                if (topDownSceneRef.current) {
                    topDownSceneRef.current.resetView();
                }
            },
        }));

        const handleGridActive = () => {
            setGridActive(!gridActive);
        };

        const toggleLayersCamera = () => {
            if (topDownSceneRef.current) {
                // reset the orbit controls view
                topDownSceneRef.current.resetView();
                setLayersCamera(!layersCamera);
            }
        };

        const toggleAxesHelper = () => {
            setAxesHelperActive(!axesHelperActive);
        };

        const deletePlate = (id: string) => {
            const newPlates = plates.filter((plate) => plate.id !== id);
            const newPlatesWithHeight = newPlates.map((plate, index) => ({
                ...plate,
                height: (index + 1) * 0.1,
            }));
            dispatch(updatePlates(newPlatesWithHeight));
            dispatch(setUnsavedChanges(true));
        };

        return (
            <Box
                component={'div'}
                width="100%"
                height="100%"
                position="relative"
            >
                {props.isDisabled && (
                    <Box
                        component={'div'}
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        bgcolor="rgba(50, 50, 50, 0.1)"
                        zIndex={10}
                    >
                        <Box component={'div'} sx={styles.loadingOverlay}>
                            <CircularProgress />
                        </Box>
                    </Box>
                )}
                <Canvas shadows>
                    {axesHelperActive && <axesHelper args={[5]} />}
                    <Suspense
                    //fallback={
                    // TODO: R3F: Circle is not part of the THREE namespace! Did you forget to extend?
                    // https://github.com/WentureIO/floorin-vista-frontend/pull/62
                    // See: https://docs.pmnd.rs/react-three-fiber/api/objects#using-3rd-party-objects-declaratively
                    // <Box
                    //     zIndex={1000}
                    //     component={'div'}
                    //     sx={styles.loadingOverlay}
                    // >
                    //     <CircularProgress />
                    // </Box>
                    //}
                    >
                        <CameraController layersCamera={layersCamera} />
                        <TopDownScene
                            ref={topDownSceneRef}
                            gridActive={gridActive}
                            plates={plates}
                            deletePlate={deletePlate}
                            platePanelContainer={platePanelContainer}
                            layersCamera={layersCamera}
                        />
                    </Suspense>
                </Canvas>
                <Stack sx={{ position: 'absolute', bottom: 20, left: 20 }}>
                    <IconButton onClick={handleGridActive}>
                        {gridActive ? <GridOn /> : <GridOff />}
                    </IconButton>

                    {/* <IconButton onClick={toggleLayersCamera}>
                        {layersCamera ? (
                            <LayersOutlined />
                        ) : (
                            <LayersClearOutlined />
                        )}
                    </IconButton>

                    <IconButton onClick={toggleAxesHelper}>
                        {axesHelperActive ? <Explore /> : <ExploreOff />}
                    </IconButton> */}
                </Stack>
                {showPortal && (
                    <div
                        ref={platePanelContainer}
                        style={{
                            position: 'absolute',
                            zIndex: 100,
                            bottom: 20,
                            alignSelf: 'center',
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }}
                    ></div>
                )}
            </Box>
        );
    }
);

export default Moodboard;

const styles = {
    loadingOverlay: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
};
