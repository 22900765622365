// Start of Selection
import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PLACEHOLDER_IMAGE_URL } from 'config';

// mui
import { Box, Typography, CircularProgress } from '@mui/material';

// ui
import ProductItemDetails from './ProductItemDetails';
import WarningBox from 'components/WarningBox';
import { colors } from 'theming/colors';

// store
import { selectDesignCodeProject } from 'features/projects/state/projectState';

// services
import { PIMService } from 'services/PIMService';

// types
import {
    ProductListViewCategorizationType,
    ProductListViewVariation,
} from './ProductListView';
import { CategorizationTypeConst } from './ProductListView';

interface ProductDisplayDetailedProps {
    currentProject: VisualOffering | undefined;
    allPopulatedVariations: ProductListViewVariation[] | undefined;
    pimProducts: PIMProduct[];
    categorizationType: ProductListViewCategorizationType;
    isProductsLoading: boolean;
    onAddNewSample: (productSampleData: ProductSampleData) => void;
    onRemoveSample: (productSampleData: ProductSampleData) => void;
    selectedSamples: ProductSampleData[];
    resetTrigger?: number; // for resetting product sample input dropdown
}

const ProductDisplayDetailed = (props: ProductDisplayDetailedProps) => {
    const { t } = useTranslation();

    // local state

    // individual image loading states and fetched snapshot urls
    const [imageLoading, setImageLoading] = useState<boolean[]>([]);
    const [fetchedSnapshotUrl, setFetchedSnapshotUrl] = useState<string[]>([]);

    // properties

    const designCodeProject = useSelector(selectDesignCodeProject);

    const baseUrl = window.location.origin;
    const {
        currentProject,
        allPopulatedVariations,
        pimProducts,
        categorizationType,
        isProductsLoading,
        onAddNewSample,
        onRemoveSample,
        selectedSamples,
        resetTrigger,
    } = props;
    const isCategorizationByProduct =
        categorizationType.id === CategorizationTypeConst.BY_PRODUCT;

    // features

    const setSnapshotLoading = (index: number, loading: boolean) => {
        setImageLoading((prev) => {
            const newArray = [...prev];
            newArray[index] = loading;
            return newArray;
        });
    };

    const processImage = useCallback(
        async (snapshotURL: string, index: number) => {
            const pimService = new PIMService();
            setSnapshotLoading(index, true);

            if (!snapshotURL) {
                setFetchedSnapshotUrl((prev) => {
                    const newArray = [...prev];
                    newArray[index] = '';
                    return newArray;
                });
                setSnapshotLoading(index, false);
                return;
            }

            if (designCodeProject) {
                const designCodeParameter = currentProject?.designCode
                    ? `?designcode=${currentProject.designCode}`
                    : '';

                setFetchedSnapshotUrl((prev) => {
                    const newArray = [...prev];
                    newArray[index] = snapshotURL + designCodeParameter;
                    return newArray;
                });
            } else {
                await pimService.getImage(snapshotURL).then((image) => {
                    if (image) {
                        setFetchedSnapshotUrl((prev) => {
                            const newArray = [...prev];
                            newArray[index] = URL.createObjectURL(image);
                            return newArray;
                        });
                    } else {
                        setFetchedSnapshotUrl((prev) => {
                            const newArray = [...prev];
                            newArray[index] = '';
                            return newArray;
                        });
                    }
                });
            }

            setSnapshotLoading(index, false);
        },
        [currentProject?.designCode, designCodeProject]
    );

    // side effects

    useEffect(() => {
        if (!allPopulatedVariations?.length) return;

        allPopulatedVariations.forEach((variation, index) => {
            if (!variation || !variation.variationId) return;

            const snapshotURL =
                variation?.variationSnapshot?.sizes?.thumbnail?.url ||
                variation?.variationSnapshot?.sizes?.medium?.url ||
                variation?.variationSnapshot?.sizes?.large?.url ||
                variation?.variationSnapshot?.url ||
                '';

            processImage(snapshotURL, index);
        });
    }, [allPopulatedVariations, processImage]);

    // categorization by product (Tooted)
    if (isCategorizationByProduct) {
        return (
            <Box component="div" sx={styles.container}>
                <Box component="div" sx={styles.productItemBox}>
                    {isProductsLoading && (
                        <CircularProgress
                            size={20}
                            sx={styles.circularLoader}
                        />
                    )}
                    {/* Product item details - PIM product info */}
                    {pimProducts.length &&
                        (() => {
                            const seen = new Set<string>();
                            return (
                                // filter out duplicates although BE also takes care of it
                                pimProducts
                                    .filter((product: PIMProduct) => {
                                        if (seen.has(product.id)) {
                                            return false;
                                        }
                                        seen.add(product.id);
                                        return true;
                                    })
                                    // sort by product name a to z
                                    .sort((a: PIMProduct, b: PIMProduct) =>
                                        a.name.localeCompare(b.name)
                                    )
                                    .map(
                                        (
                                            product: PIMProduct,
                                            index: number,
                                            array: PIMProduct[]
                                        ) => {
                                            // ui collection off from active production
                                            const isPimCategoryOffFromActiveProduction =
                                                product.category?.value
                                                    ?.inActiveProduction !==
                                                    undefined &&
                                                !product.category?.value
                                                    ?.inActiveProduction;
                                            // ui collection off from active sale
                                            const isPimCategoryOffFromActiveSale =
                                                product.category?.value
                                                    ?.inActiveSale !==
                                                    undefined &&
                                                !product.category?.value
                                                    ?.inActiveSale;
                                            const isProductOffFromActiveProduction =
                                                product.inActiveProduction !==
                                                    undefined &&
                                                !product.inActiveProduction;
                                            const isProductOffFromActiveSale =
                                                product.inActiveSale !==
                                                    undefined &&
                                                !product.inActiveSale;
                                            return (
                                                <React.Fragment
                                                    key={product.id}
                                                >
                                                    {(isProductOffFromActiveProduction ||
                                                        isPimCategoryOffFromActiveProduction ||
                                                        isProductOffFromActiveSale ||
                                                        isPimCategoryOffFromActiveSale) && (
                                                        <WarningBox
                                                            text={
                                                                isProductOffFromActiveProduction ||
                                                                isPimCategoryOffFromActiveProduction
                                                                    ? t(
                                                                          'views.productList.notInActiveProduction'
                                                                      )
                                                                    : isProductOffFromActiveSale ||
                                                                      isPimCategoryOffFromActiveSale
                                                                    ? t(
                                                                          'views.productList.notInActiveSales'
                                                                      )
                                                                    : ''
                                                            }
                                                        />
                                                    )}
                                                    <ProductItemDetails
                                                        key={product.id}
                                                        product={product}
                                                        index={index}
                                                        array={array}
                                                        translate={t}
                                                        categorizationType={
                                                            categorizationType
                                                        }
                                                        onAddNewSample={
                                                            onAddNewSample
                                                        }
                                                        onRemoveSample={
                                                            onRemoveSample
                                                        }
                                                        selectedSamples={
                                                            selectedSamples
                                                        }
                                                        resetTrigger={
                                                            resetTrigger
                                                        }
                                                    />
                                                </React.Fragment>
                                            );
                                        }
                                    )
                            );
                        })()}
                </Box>
            </Box>
        );
    }

    // categorization by room (Ruumid)
    return (
        <Box component="div" sx={styles.container}>
            {allPopulatedVariations
                // sort by room name a to z
                ?.sort((a, b) => a.roomName.localeCompare(b.roomName))
                ?.map((variation: ProductListViewVariation, index: number) => {
                    if (!variation || !variation.variationId) return null;

                    /* Product item */
                    return (
                        <Box
                            component="div"
                            key={variation.variationId}
                            sx={{
                                ...styles.productItemBox,
                                borderTop:
                                    index > 0
                                        ? '1px solid ' + colors.borderGrey
                                        : 'none',
                            }}
                            data-variation-container // for pdf export
                        >
                            {/* Product item header - Room / variation info */}

                            <Box
                                component="div"
                                sx={styles.roomGroup}
                                data-product-header
                            >
                                <Box
                                    component="div"
                                    sx={styles.roomGroupImageContainer}
                                >
                                    <Box
                                        component="div"
                                        sx={styles.roomGroupImage}
                                    >
                                        {imageLoading[index] && (
                                            <CircularProgress
                                                size={20}
                                                sx={styles.circularLoader}
                                            />
                                        )}

                                        {!imageLoading[index] && (
                                            <img
                                                src={fetchedSnapshotUrl[index]}
                                                alt={'thumbnail'}
                                                onError={(
                                                    e: React.SyntheticEvent<
                                                        HTMLImageElement,
                                                        Event
                                                    >
                                                ) => {
                                                    e.currentTarget.onerror =
                                                        null;
                                                    e.currentTarget.src = `${baseUrl}${PLACEHOLDER_IMAGE_URL}`;
                                                }}
                                                style={{
                                                    width: '6rem',
                                                    height: '6rem',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                                <Box component="div" sx={styles.roomGroupTitle}>
                                    <Typography
                                        sx={{
                                            ...styles.roomGroupTitleText,
                                            fontWeight: '600',
                                        }}
                                    >
                                        {variation.roomName}
                                    </Typography>
                                    <Box component="div">
                                        <Typography
                                            sx={{
                                                ...styles.roomGroupTitleSubText,
                                            }}
                                        >
                                            {t(
                                                'views.productList.variationName'
                                            ).toUpperCase()}
                                            {': '}
                                            {variation.variationName}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            {/* Product item details - PIM product info */}
                            {isProductsLoading && (
                                <CircularProgress
                                    size={20}
                                    sx={styles.circularLoader}
                                />
                            )}
                            {pimProducts.length &&
                                pimProducts
                                    .filter((product: PIMProduct) =>
                                        variation.variationData.plates.some(
                                            (plate: any) =>
                                                plate.pimProductId ===
                                                product.id
                                        )
                                    )
                                    // sort by product name a to z
                                    .sort((a: PIMProduct, b: PIMProduct) =>
                                        a.name.localeCompare(b.name)
                                    )
                                    .map(
                                        (
                                            product: PIMProduct,
                                            index: number,
                                            array: PIMProduct[]
                                        ) => {
                                            // ui collection off from active production
                                            const isPimCategoryOffFromActiveProduction =
                                                product.category?.value
                                                    ?.inActiveProduction !==
                                                    undefined &&
                                                !product.category?.value
                                                    ?.inActiveProduction;
                                            // ui collection off from active sale
                                            const isPimCategoryOffFromActiveSale =
                                                product.category?.value
                                                    ?.inActiveSale !==
                                                    undefined &&
                                                !product.category?.value
                                                    ?.inActiveSale;
                                            const isProductOffFromActiveProduction =
                                                product.inActiveProduction !==
                                                    undefined &&
                                                !product.inActiveProduction;
                                            const isProductOffFromActiveSale =
                                                product.inActiveSale !==
                                                    undefined &&
                                                !product.inActiveSale;

                                            return (
                                                <React.Fragment
                                                    key={product.id}
                                                >
                                                    {(isProductOffFromActiveProduction ||
                                                        isPimCategoryOffFromActiveProduction ||
                                                        isProductOffFromActiveSale ||
                                                        isPimCategoryOffFromActiveSale) && (
                                                        <WarningBox
                                                            text={
                                                                isProductOffFromActiveProduction ||
                                                                isPimCategoryOffFromActiveProduction
                                                                    ? t(
                                                                          'views.productList.notInActiveProduction'
                                                                      )
                                                                    : isProductOffFromActiveSale ||
                                                                      isPimCategoryOffFromActiveSale
                                                                    ? t(
                                                                          'views.productList.notInActiveSales'
                                                                      )
                                                                    : ''
                                                            }
                                                        />
                                                    )}
                                                    <ProductItemDetails
                                                        key={product.id}
                                                        product={product}
                                                        index={index}
                                                        array={array}
                                                        translate={t}
                                                        categorizationType={
                                                            categorizationType
                                                        }
                                                        onAddNewSample={
                                                            onAddNewSample
                                                        }
                                                        onRemoveSample={
                                                            onRemoveSample
                                                        }
                                                        selectedSamples={
                                                            selectedSamples
                                                        }
                                                        resetTrigger={
                                                            resetTrigger
                                                        }
                                                    />
                                                </React.Fragment>
                                            );
                                        }
                                    )}
                        </Box>
                    );
                })}
        </Box>
    );
};

export default ProductDisplayDetailed;

const styles = {
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '0.5rem',

        boxSizing: 'border-box',
    },

    // Product item box which groups all the rest
    // about the variation and it's product
    productItemBox: {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '1rem',

        boxSizing: 'border-box',
        padding: '2rem 5rem 0.5rem 5rem',
    },

    // -- Product item section header --
    roomGroup: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',

        gap: '1rem',
    },
    roomGroupImageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        maxWidth: '8rem',
        maxHeight: '8rem',
        aspectRatio: '1/ 1',
        borderRadius: '50%',
        overflow: 'hidden',
    },
    roomGroupImage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    roomGroupTitle: {
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '0',
    },
    roomGroupTitleText: {
        fontSize: '1.2rem',
    },
    roomGroupTitleSubText: {
        fontSize: '0.8rem',
    },
    circularLoader: {
        margin: 'auto',
        marginTop: '1rem',
    },
};
